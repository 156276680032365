/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Stack, StackItem } from '@fluentui/react';
import Box from 'components/Box';
import { observer } from 'mobx-react';
import { useCurrentDocSet } from 'store/useDocumentSets';
import CollectionSelector from 'components/CollectionSelector';
import RenameDocSet from './RenameDocSet';

const stackTokens = { childrenGap: 16 };

const TabContainer: React.FC = ({ children }) => {
  const docSet = useCurrentDocSet();

  return (
    <Stack tokens={stackTokens}>
      <StackItem>
        <Box mt={16}>
          <CollectionSelector documentSet={docSet} />
        </Box>
        <RenameDocSet documentSet={docSet} />
      </StackItem>
      <StackItem>{children}</StackItem>
    </Stack>
  );
};

export default observer(TabContainer);
