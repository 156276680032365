import React, { useEffect } from 'react';
import TextInput from 'components/TextInput';
import {
  Checkbox,
  DefaultButton,
  PrimaryButton,
  Stack,
  StackItem,
} from '@fluentui/react';
import { useForm } from 'react-hook-form';
import { useCurrentDocSet } from 'store/useDocumentSets';
import { Snippet } from 'store/snippets';
import { action } from 'mobx';
import DeleteSnippetButton from './DeleteSnippetButton';

interface EditSnippetFormValues {
  name: string;
  inline: boolean;
}

const REQUIRED = { required: true };

interface AddSnippetFormProps {
  onClose: () => void;
  snippet: Snippet;
}

const stackTokens = { childrenGap: 12 };

const AddSnippetForm: React.FC<AddSnippetFormProps> = ({
  onClose,
  snippet,
}) => {
  const docSet = useCurrentDocSet();
  const { register, handleSubmit, errors, setValue, formState } = useForm<
    EditSnippetFormValues
  >({
    reValidateMode: 'onChange',
    defaultValues: { name: snippet.name, inline: snippet.inline || false },
  });

  useEffect(() => {
    register({ name: 'inline' });
  }, [register]);

  if (!docSet) {
    return null;
  }

  const handler = handleSubmit(
    async ({ name, inline }: EditSnippetFormValues) => {
      await action(async () => {
        await docSet.updateSnippet(snippet.id, name, inline);
        onClose();
      })();
    }
  );

  return (
    <form onSubmit={handler}>
      <Stack tokens={stackTokens}>
        <StackItem>
          <TextInput
            focusOnMount
            register={register}
            setValue={setValue}
            name="name"
            rules={REQUIRED}
            label="Name"
            error={errors.name}
          />
        </StackItem>
        <StackItem>
          <Checkbox
            label="Inline snippet"
            name="inline"
            defaultChecked={snippet.inline}
            onChange={(_, checked) =>
              setValue('inline', checked || false, {
                shouldDirty: true,
              })
            }
          />
        </StackItem>
        <StackItem>
          <Stack horizontal horizontalAlign="space-between">
            <StackItem>
              <PrimaryButton type="submit" disabled={formState.isSubmitting}>
                Save
              </PrimaryButton>
            </StackItem>
            <StackItem>
              <DefaultButton onClick={onClose}>Cancel</DefaultButton>
            </StackItem>
          </Stack>
        </StackItem>
        <StackItem>
          <DeleteSnippetButton onDelete={onClose} snippet={snippet} />
        </StackItem>
      </Stack>
    </form>
  );
};

export default AddSnippetForm;
