/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from '@emotion/styled';
import { Link, Text, FontIcon } from '@fluentui/react';
import Box from 'components/Box';
import { useToggle } from 'components/hooks/useToggle';
import { observer } from 'mobx-react';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import {
  CategoryWithSnippets,
  DEFAULT_CATEGORY,
  Snippet,
} from 'store/snippets';
import { useCurrentDocSet } from 'store/useDocumentSets';
import RenameCategoryForm from './RenameCategoryForm';
import { DraggableSnippetItem } from './SnippetListItem';
import AddSnippet from './snippet/AddSnippet';

interface SnippetCategoryProps {
  category: CategoryWithSnippets;
  index: number;
  count: number;
  snippets: Snippet[];
}

const Title = styled(Box)`
  flex: 1;
`;
const Actions = styled(Box)``;

const ActionIcon = styled(FontIcon)`
  margin: 0 4px 0 4px;
`;

const SnippetCategory: React.FC<SnippetCategoryProps> = ({
  category,
  snippets,
  index,
  count,
}) => {
  const docSet = useCurrentDocSet();
  const [showRename, toggle] = useToggle();
  const snippetItems = snippets.map((s, idx) => (
    <DraggableSnippetItem key={s.id} snippet={s} index={idx} />
  ));

  const move = (value: number) => {
    return () => {
      docSet?.changeCategoryOrder(index, index + value);
    };
  };

  const remove = () => {
    docSet?.removeCategory(category);
  };
  const isDefault = category.id === DEFAULT_CATEGORY;
  const isTop = index === 0;
  const isBottom = index === count - 1;

  return (
    <>
      <Droppable droppableId={category.id}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <Box mb={8} display="flex">
              <Title>
                <Text variant="large">{category.name}</Text>
                <Link onClick={toggle}>
                  <ActionIcon iconName="Edit" />
                </Link>
                {!isDefault && (
                  <Link onClick={remove}>
                    <ActionIcon iconName="Delete" />
                  </Link>
                )}
              </Title>
              <Actions>
                {!isTop && (
                  <Link onClick={move(-1)}>
                    <ActionIcon iconName="CaretSolidUp" />
                  </Link>
                )}
                {!isBottom && (
                  <Link onClick={move(1)}>
                    <ActionIcon iconName="CaretSolidDown" />
                  </Link>
                )}
              </Actions>
            </Box>
            <RenameCategoryForm
              category={category}
              onClose={toggle}
              show={showRename}
            />
            {snippetItems}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <Box py={8}>
        <AddSnippet category={category} />
      </Box>
    </>
  );
};

export default observer(SnippetCategory);
