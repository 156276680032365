import { mergeStyles } from '@fluentui/react';
import { AnimationStyles } from '@fluentui/react/lib/Styling';
import Box from 'components/Box';
import NewSingleEntryForm from 'components/NewSingleEntryForm';
import { observer } from 'mobx-react';
import React from 'react';
import { DocumentSet } from 'store/snippets';

interface RenameFormProps {
  documentSet: DocumentSet;
  onClose: () => void;
}

const boxClass = mergeStyles(AnimationStyles.slideDownIn20);

const RenameForm: React.FC<RenameFormProps> = ({ onClose, documentSet }) => {
  const onSubmit = async (value: string) => {
    if (documentSet) {
      // eslint-disable-next-line no-param-reassign
      documentSet.name = value;
      onClose();
    }
  };

  if (!documentSet) {
    return null;
  }
  return (
    <Box className={boxClass} mb={8}>
      <NewSingleEntryForm
        onSubmit={onSubmit}
        primaryButtonLabel="Save"
        onClose={onClose}
        defaultValue={documentSet.name}
      />
    </Box>
  );
};

export default observer(RenameForm);
