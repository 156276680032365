import { useAuthStore } from 'AppContextProvider';
import { createLocation } from 'history';
import { AuthType, routes } from 'routes';
import { useHistory } from 'react-router-dom';

export function useHandleAuth(authType: AuthType) {
  const authStore = useAuthStore();
  const history = useHistory();

  return () => {
    if (window.Word) {
      const newLocation = createLocation(routes.auth(authType));
      const href = history.createHref(newLocation);
      const target = `${window.location.protocol}//${window.location.host}${href}`;
      Office.context.ui.displayDialogAsync(
        target,
        { height: 50, width: 30 },
        (asyncResult) => {
          asyncResult.value.addEventHandler(
            Office.EventType.DialogMessageReceived,
            () => {
              asyncResult.value.close();
            }
          );
        }
      );
    } else if (authType === AuthType.google) {
      authStore.signInWithGoogle();
    } else if (authType === AuthType.microsoft) {
      authStore.signInWithMS();
    }
  };
}
