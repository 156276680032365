export function wordRun(
  batch: (context: Word.RequestContext) => Promise<void>
) {
  if (window && window.Word) {
    return Word.run(batch);
  }
  console.error('Unable to run, application is not running in word');
}

export function useWordRun() {
  return wordRun;
}
