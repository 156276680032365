import { useCurrentWorkspace } from './useWorkspaces';

export function useCurrentDocSet() {
  const { workspace } = useCurrentWorkspace();
  return workspace?.currentDocSet;
}

export function useCurrentDocumentSets() {
  const { workspace } = useCurrentWorkspace();
  return workspace?.documentSets;
}
