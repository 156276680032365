import { IStackTokens, Stack, StackItem, Text } from '@fluentui/react';
import Box from 'components/Box';
import { observer } from 'mobx-react';
import React from 'react';
import { useCurrentDocSet } from 'store/useDocumentSets';
import DropFiles from './DropFiles';
import AddSnippet from './snippet/AddSnippet';

const stackTokens: IStackTokens = {
  childrenGap: 18,
};

const NoSnippets: React.FC = () => {
  const documentSet = useCurrentDocSet();
  return (
    <Box pt={8}>
      <Stack tokens={stackTokens}>
        <StackItem>
          <Text variant="large">This collection is currently empty.</Text>
        </StackItem>
        <StackItem>
          <Text>
            To add a new snippet start by selecting a section in your document
            then click Create
          </Text>
        </StackItem>
        <StackItem>
          <AddSnippet type="button" />
        </StackItem>
        <StackItem>
          Alternatively if you have word documents that you would like to use as
          a snippet you can upload them here.
        </StackItem>
        <StackItem>
          <DropFiles documentSet={documentSet} />
        </StackItem>
      </Stack>
    </Box>
  );
};

export default observer(NoSnippets);
