import React from 'react';
import {
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  StackItem,
} from '@fluentui/react';
import { DocumentSet } from 'store/snippets';
import NewDocSetForm from 'manage/NewDocSetForm';
import { useCurrentWorkspace } from 'store/useWorkspaces';
import MenuButton from './MenuButtom';
import Box from './Box';
import { useToggle } from './hooks/useToggle';

interface PickCollectionPanelProps {
  open?: boolean;
  onClose?: () => void;
}

const stackTokens = { childrenGap: 12 };

const PickCollectionPanel: React.FC<PickCollectionPanelProps> = ({
  open,
  onClose,
}) => {
  const { workspace } = useCurrentWorkspace();
  const [showCreate, toggle, setShowCreate] = useToggle();

  if (!workspace) {
    return null;
  }

  const handleClose = () => {
    setShowCreate(false);
    if (onClose) {
      onClose();
    }
  };
  const selectCollection = (ds: DocumentSet) => {
    workspace.currentDocSet = ds;
    handleClose();
  };
  const workspaces = workspace.documentSets.docs.map((c) => (
    <StackItem key={c.id} align="stretch">
      <MenuButton
        onClick={() => selectCollection(c)}
        name={c.name}
        active={workspace.currentDocSet?.id === c.id}
      />
    </StackItem>
  ));

  return (
    <Panel
      isOpen={open}
      onDismiss={handleClose}
      type={PanelType.smallFixedFar}
      closeButtonAriaLabel="Close"
      headerText="Select Collection"
    >
      <Box mt={20}>
        <Stack tokens={stackTokens}>{workspaces}</Stack>
      </Box>
      {!showCreate && (
        <Box mt={20}>
          <PrimaryButton onClick={toggle}>+ New Collection</PrimaryButton>
        </Box>
      )}
      {showCreate && (
        <Box mt={20}>
          <NewDocSetForm onClose={toggle} onComplete={handleClose} />
        </Box>
      )}
    </Panel>
  );
};

export default PickCollectionPanel;
