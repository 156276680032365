import Auth from 'account/Auth';
import Recover from 'account/Recover';
import AppContainer from 'AppContainer';
import { useAuthStore, useWorkspaceStore } from 'AppContextProvider';
import Builder from 'builder/Builder';
import ProtectedRoute from 'components/ProtectedRoute';
import Loading from 'Loading';
import Manage from 'manage/Manage';
import NewCollection from 'manage/NewCollection';
import { observer } from 'mobx-react';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CreateTeam from 'welcome/CreateTeam';
import Login from 'account/Login';
import SignUp from 'welcome/SignUp';
import Verify from 'welcome/Verify';
import Welcome from 'welcome/Welcome';
import { routes } from './routes';

function App() {
  const authStore = useAuthStore();
  const workspaceStore = useWorkspaceStore();

  if (!authStore.init) {
    return <Loading />;
  }

  if (workspaceStore.loading) {
    return <Loading />;
  }

  if (authStore.currentUser && !authStore.verified) {
    return (
      <AppContainer>
        <Verify />
      </AppContainer>
    );
  }
  return (
    <AppContainer>
      <Switch>
        <Route exact path={routes.home}>
          <Welcome />
        </Route>
        <Route path={routes.signup}>
          <SignUp />
        </Route>
        <Route path={routes.login}>
          <Login />
        </Route>
        <Route path={routes.auth()}>
          <Auth />
        </Route>
        <Route path={routes.verify}>
          <Verify />
        </Route>
        <Route path={routes.recover}>
          <Recover />
        </Route>
        <ProtectedRoute path={routes.create}>
          <CreateTeam />
        </ProtectedRoute>
        <ProtectedRoute path={routes.build}>
          <Builder />
        </ProtectedRoute>
        <ProtectedRoute path={routes.collection} exact>
          <Manage />
        </ProtectedRoute>
        <ProtectedRoute path={routes.newCollection} exact>
          <NewCollection />
        </ProtectedRoute>
      </Switch>
    </AppContainer>
  );
}

export default observer(App);
