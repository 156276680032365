import { Stack, StackItem, Text } from '@fluentui/react';
import Box from 'components/Box';
import { useNavigateTo } from 'components/hooks/useNavigateTo';
import React from 'react';
import { routes } from '../routes';
import NewDocSetForm from './NewDocSetForm';

const stackTokens = { childrenGap: 12 };

const NewCollection: React.FC = () => {
  const toManage = useNavigateTo(routes.collection);

  return (
    <Stack tokens={stackTokens}>
      <StackItem align="center">
        <Box py={20}>
          <Text variant="xxLarge">New Collection</Text>
        </Box>
      </StackItem>
      <StackItem>
        <NewDocSetForm onComplete={toManage} />
      </StackItem>
    </Stack>
  );
};

export default NewCollection;
