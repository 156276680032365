import {
  IStackItemStyles,
  PrimaryButton,
  Stack,
  StackItem,
  Text,
} from '@fluentui/react';
import { Link, useHistory } from 'react-router-dom';
import React from 'react';
import Box from 'components/Box';
import RouterLink from 'components/RouterLink';
import { useNavigateTo } from 'components/hooks/useNavigateTo';
import { useAutorun } from 'components/hooks/useAutorun';
import { useAuthStore, useWorkspaceStore } from 'AppContextProvider';
import Loading from 'Loading';
import { observer } from 'mobx-react';
import FadeIn from 'components/FadeIn';
import logo from './icon-80.png';
import { routes } from '../routes';

const logoStyle: IStackItemStyles = {
  root: {
    marginTop: 8 * 4,
  },
};

const stackTokens = { childrenGap: 42 };

function useAutoLogin() {
  const authStore = useAuthStore();

  const history = useHistory();
  const workspaceStore = useWorkspaceStore();

  useAutorun(() => {
    if (authStore.currentUser && workspaceStore.workspaces?.isLoaded) {
      if (workspaceStore.currentWorkspace) {
        history.push(routes.build);
      } else {
        history.push(routes.create);
      }
    }
  });

  const ready = authStore.init;
  const user = authStore.currentUser;
  return [ready, user];
}

const Welcome: React.FC = () => {
  const toSignUp = useNavigateTo(routes.signup);

  const [ready, user] = useAutoLogin();
  if (!ready || user) {
    return <Loading />;
  }
  return (
    <FadeIn>
      <Stack tokens={stackTokens}>
        <StackItem styles={logoStyle} align="center">
          <Box>
            <img src={logo} alt="DilBert" />
          </Box>
        </StackItem>
        <StackItem align="center">
          <Box py={16}>
            <Text variant="xxLarge">DilBert</Text>
          </Box>
        </StackItem>
        <StackItem align="center">
          <Text variant="xLarge">Document building made easy</Text>
        </StackItem>
        <StackItem align="center">
          <Link to={routes.signup} component={PrimaryButton} onClick={toSignUp}>
            Get Started
          </Link>
        </StackItem>
        <StackItem align="center">
          <Text>
            Already have an account?{' '}
            <RouterLink to={routes.login}>Sign In Instead</RouterLink>
          </Text>
        </StackItem>
      </Stack>
    </FadeIn>
  );
};

export default observer(Welcome);
