import React from 'react';
import { IconButton, Panel, PanelType } from '@fluentui/react';
import styled from '@emotion/styled';
import { useToggle } from './hooks/useToggle';
import Nav from './Nav';

const StyledIconButton = styled(IconButton)`
  padding: 20px;
  margin-left: -8px;
`;

const MenuIcon: React.FC = () => {
  const [isOpen, toggle] = useToggle(false);

  return (
    <>
      <StyledIconButton
        iconProps={{ iconName: 'GlobalNavButton' }}
        onClick={toggle}
      />
      <Panel
        isOpen={isOpen}
        onDismiss={toggle}
        type={PanelType.smallFixedNear}
        closeButtonAriaLabel="Close"
        headerText="Menu"
        isLightDismiss
      >
        <Nav />
      </Panel>
    </>
  );
};

export default MenuIcon;
