import { Pivot, PivotItem, Stack, StackItem } from '@fluentui/react';
import { useWorkspaceStore } from 'AppContextProvider';
import Box from 'components/Box';
import MenuIcon from 'components/MenuIcon';
import NewCollection from 'manage/NewCollection';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import Assemble from './Assemble';
import Populate from './Populate';

const Builder: React.FC = () => {
  const workspaceStore = useWorkspaceStore();
  const { currentWorkspace } = workspaceStore;

  const [panel, setSelectedPanel] = React.useState('assemble');

  const handleLinkClick = useCallback(
    (item?: PivotItem) => {
      setSelectedPanel(item?.props.itemKey || 'assemble');
    },
    [setSelectedPanel]
  );

  if (!currentWorkspace || currentWorkspace.documentSets.isLoading) {
    return null;
  }

  if (!currentWorkspace.documentSets.hasDocs) {
    return <NewCollection />;
  }

  return (
    <>
      <Stack horizontal verticalAlign="center">
        <StackItem>
          <MenuIcon />
        </StackItem>
        <StackItem>
          <Pivot
            aria-label="Create Document"
            headersOnly
            onLinkClick={handleLinkClick}
          >
            <PivotItem headerText="Assemble" itemKey="assemble" />
            <PivotItem headerText="Populate" itemKey="populate" />
          </Pivot>
        </StackItem>
      </Stack>
      <Box mr={8}>
        {panel === 'assemble' && <Assemble />}
        {panel === 'populate' && <Populate />}
      </Box>
    </>
  );
};

export default observer(Builder);
