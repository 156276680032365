import { Snippet } from 'store/snippets';
import Axios from 'axios';
import { wordRun } from 'components/hooks/useWordRun';

function snippetUrl(path: string) {
  const encodedPath = encodeURIComponent(path);
  return `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_BUCKET}/o/${encodedPath}?alt=media`;
}

export async function handleInsert(snip: Snippet) {
  console.error('TODO: Implement snippet', snip);

  const response = await Axios.get(snippetUrl(snip.path || ''));
  return wordRun(async (context) => {
    try {
      const current = context.document.getSelection();

      if (snip.encoding === 'base64') {
        const range = current.insertFileFromBase64(
          response.data,
          Word.InsertLocation.replace
        );

        range
          .insertParagraph('', Word.InsertLocation.after)
          .select(Word.SelectionMode.end);
      } else {
        const range = current.insertOoxml(
          response.data,
          Word.InsertLocation.before
        );
        range.select(Word.SelectionMode.end);

        // This moves the cursor to the next line, only needed if the snippet ends with a paragraph
        // const p = range.paragraphs.items[range.paragraphs.items.length - 1];
        const lastInserted = range.paragraphs.getLastOrNullObject();
        if (lastInserted) {
          const next = lastInserted.getNext();
          if (next) {
            next.select(Word.SelectionMode.start);
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  });
}

// async function replace() {

//   return Word.run(async context => {
//     const data = context.document.body.search("${full company name}");
//     data.load();
//     await context.sync();
//     console.log("Hi");
//     try {
//     console.log(data.items);
//     data.items.forEach((r)=> {
//       r.insertText('other', Word.InsertLocation.replace);
//     });
//     await context.sync();
//     } catch(e) {
//       console.log(e);
//     }
//   })
// };
