/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, Text } from '@fluentui/react';
import styled from '@emotion/styled';
import { DocumentSet } from 'store/snippets';
import PickCollectionPanel from './PickCollectionPanel';
import { useToggle } from './hooks/useToggle';
import Box from './Box';

const NameContainer = styled(Box)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 1px;
  flex: 1;
`;

interface CollectionSelectorProps {
  documentSet?: DocumentSet;
}

const CollectionSelector: React.FC<CollectionSelectorProps> = ({
  documentSet,
}) => {
  const [open, togglePickCollection] = useToggle(false);

  return (
    <Box display="flex" alignItems="center">
      <NameContainer mr={4}>
        <Text variant="xLarge">{documentSet?.name}</Text>
      </NameContainer>
      <Box>
        <Link variant="smallPlus" onClick={togglePickCollection}>
          Change
        </Link>
        <PickCollectionPanel open={open} onClose={togglePickCollection} />
      </Box>
    </Box>
  );
};

export default CollectionSelector;
