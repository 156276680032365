/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useCurrentDocSet } from 'store/useDocumentSets';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { CategoryWithSnippets } from 'store/snippets';
import TabContainer from './TabContainer';
import NoSnippets from './NoSnippets';
import NewCategoryForm from './NewCategoryForm';
import SnippetCategory from './SnippetCategory';

const empty: CategoryWithSnippets[] = [];
const ManageSnippets: React.FC = () => {
  const docSet = useCurrentDocSet();
  const [categories, setCategories] = useState(
    docSet?.snippetsByCategory || empty
  );
  const updating = useRef(false);

  const current = docSet?.snippetsByCategory || empty;

  useEffect(() => {
    if (!updating.current) {
      setCategories(current);
    }
  }, [current]);

  if (!docSet) {
    return <div>Create a collection first</div>;
  }

  const hasSnippets = docSet.snippets.length > 0;

  const handleDragEnd = async (result: DropResult) => {
    const snippet = docSet.snippets.find((s) => result.draggableId === s.id);
    if (snippet) {
      if (result.destination) {
        updating.current = true;
        const rearrange = docSet.rearrange(
          categories,
          result.draggableId,
          result.destination.droppableId,
          result.destination.index
        );

        if (rearrange) {
          setCategories([...rearrange.updated] || empty);
          await rearrange.promise;
          updating.current = false;
        }
      }
    }
  };

  const cats = categories.map((c, idx) => (
    <SnippetCategory
      key={c.id}
      category={c}
      index={idx}
      count={categories.length}
      snippets={c.snippets}
    />
  ));
  return (
    <TabContainer>
      {!hasSnippets && <NoSnippets />}
      {hasSnippets && (
        <>
          <DragDropContext onDragEnd={handleDragEnd}>{cats}</DragDropContext>
          <NewCategoryForm />
        </>
      )}
    </TabContainer>
  );
};

export default observer(ManageSnippets);
