import { Collection } from '@garthomite/firestorter';
import { useWorkspaceStore } from 'AppContextProvider';
import { Workspace } from './workspace';

export function useWorkspaces(): Collection<Workspace> {
  const store = useWorkspaceStore();
  if (!store.workspaces) {
    throw new Error('Not logged in');
  }
  return store.workspaces;
}

export function useCurrentWorkspace() {
  const store = useWorkspaceStore();
  return {
    loading: store.workspaces?.isLoading,
    workspace: store.currentWorkspace,
  };
}
