import { useCallback, useState } from 'react';

export function useToggle(initial?: boolean) {
  const [value, setValue] = useState(!!initial);
  const toggle = useCallback(() => {
    setValue(!value);
  }, [setValue, value]);

  return [value, toggle, setValue] as const;
}
