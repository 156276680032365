import React, { useCallback } from 'react';
import {
  INavLink,
  INavLinkGroup,
  INavStyles,
  Nav as FluidNav,
} from '@fluentui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from 'routes';
import { useAuthStore } from 'AppContextProvider';
import { observer } from 'mobx-react';

const navStyles: Partial<INavStyles> = {
  root: { marginTop: 4, marginLeft: 0 },
};

const Nav: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const authStore = useAuthStore();

  const navLinkGroups: INavLinkGroup[] = [
    {
      links: [
        {
          name: 'Build Document',
          url: routes.build,
          key: routes.build,
        },
        {
          name: 'Manage Snippets',
          url: routes.collection,
          key: routes.collection,
        },
        {
          name: 'Select Workspace',
          url: routes.create,
          key: routes.create,
        },
        {
          name: 'Sign out',
          url: routes.home,
          key: routes.home,
          onClick: (e) => {
            authStore.signOut();
            e?.preventDefault();
          },
          icon: 'SignOut',
        },
      ],
    },
  ];

  const handleClick = useCallback(
    (e?: React.MouseEvent<unknown>, link?: INavLink) => {
      if (link) {
        history.push(link.url);
      }
      if (e) {
        e.preventDefault();
      }
    },
    [history]
  );
  return (
    <FluidNav
      onLinkClick={handleClick}
      ariaLabel="Navigation"
      styles={navStyles}
      groups={navLinkGroups}
      selectedKey={location.pathname}
    />
  );
};

export default observer(Nav);
