import { TextField } from '@fluentui/react';
import React from 'react';

export enum DocumentFieldType {
  String = 'string',
}

export interface DocumentField {
  label: string;
  name: string;
  value?: string;
  type: DocumentFieldType;
}

interface FormFieldProps {
  field: DocumentField;
}

const FormField: React.FC<FormFieldProps> = ({ field }) => {
  const { label, name, value } = field;
  return <TextField label={label} name={name} defaultValue={value} />;
};

export default FormField;
