import React from 'react';
import NewSingleEntryForm from 'components/NewSingleEntryForm';
import { useCurrentDocSet } from 'store/useDocumentSets';
import { observer } from 'mobx-react';
import { useToggle } from 'components/hooks/useToggle';
import { DefaultButton, mergeStyles } from '@fluentui/react';
import Box from 'components/Box';
import { AnimationStyles } from '@fluentui/react/lib/Styling';

const boxClass = mergeStyles(AnimationStyles.slideDownIn20);

const NewCategoryForm: React.FC = () => {
  const docSet = useCurrentDocSet();
  const [showForm, toggle] = useToggle();

  if (!docSet) {
    return null;
  }

  const onSubmit = async (name: string) => {
    await docSet.addCategory(name);
    toggle();
  };

  if (!showForm) {
    return (
      <Box mt={8}>
        <DefaultButton onClick={toggle}>New Category</DefaultButton>
      </Box>
    );
  }

  return (
    <Box className={boxClass}>
      <NewSingleEntryForm
        label="Category Name"
        onSubmit={onSubmit}
        onClose={toggle}
      />
    </Box>
  );
};

export default observer(NewCategoryForm);
