import React from 'react';
import { DefaultButton, IButtonProps } from '@fluentui/react';
import { AuthType } from 'routes';
import Box from './Box';
import { ReactComponent as MSIcon } from './ms-icon.svg';
import { useHandleAuth } from './useHandleAuth';

interface MicrosoftButtonProps extends IButtonProps {
  signUp?: boolean;
}

const styles = { flexContainer: { justifyContent: 'flex-start' } };

const MicrosoftButton: React.FC<MicrosoftButtonProps> = ({ style, signUp }) => {
  const handleAuth = useHandleAuth(AuthType.microsoft);
  const newStyle = {
    ...style,
    padding: 20,
    fontWeight: 500,
    maxWidth: 260,
  };
  return (
    <DefaultButton style={newStyle} styles={styles} onClick={handleAuth}>
      <Box mr={12} pt={2}>
        <MSIcon width="20" height="30" />
      </Box>
      {signUp ? 'Sign up with Microsoft' : 'Sign in with Microsoft'}
    </DefaultButton>
  );
};

export default MicrosoftButton;
