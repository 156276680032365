import { IStackTokens, Stack, StackItem } from '@fluentui/react';
import Loading from 'Loading';
import { observer } from 'mobx-react';
import React from 'react';
import { useWorkspaces } from 'store/useWorkspaces';
import CreateTeamForm from './CreateTeamForm';
import PickWorkspace from './PickWorkspace';

const stackTokens: IStackTokens = { childrenGap: 12, padding: 12 };

const CreateTeam: React.FC = () => {
  const workspaces = useWorkspaces();

  if (workspaces.isLoading) {
    return <Loading />;
  }

  const hasSpaces = workspaces.docs.length > 0;

  return (
    <Stack tokens={stackTokens}>
      {hasSpaces && (
        <StackItem>
          <PickWorkspace />
        </StackItem>
      )}
      <StackItem>
        <CreateTeamForm />
      </StackItem>
    </Stack>
  );
};

export default observer(CreateTeam);
