/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from 'react';
import {
  DefaultButton,
  Link,
  Panel,
  PanelType,
  Stack,
  StackItem,
  Text,
} from '@fluentui/react';
import Box from 'components/Box';
import { SnippetCategory } from 'store/snippets';
import { useToggle } from 'components/hooks/useToggle';
import AddSnippetForm from './AddSnippetForm';

interface AddSnippetProps {
  category?: SnippetCategory;
  type?: 'button' | 'link';
}

const stackTokens = { childrenGap: 12 };

const AddSnippet: React.FC<AddSnippetProps> = ({ category, type = 'link' }) => {
  const [open, toggleEditSnippet] = useToggle(false);
  return (
    <span>
      {type === 'link' && (
        <Link variant="smallPlus" onClick={toggleEditSnippet}>
          New Snippet
        </Link>
      )}
      {type === 'button' && (
        <DefaultButton onClick={toggleEditSnippet}>
          Create Snippet
        </DefaultButton>
      )}

      <Panel
        isOpen={open}
        onDismiss={toggleEditSnippet}
        type={PanelType.smallFixedFar}
        closeButtonAriaLabel="Close"
        headerText="Add Snippet"
      >
        {open && (
          <Box mt={20}>
            <Stack tokens={stackTokens}>
              <StackItem>
                <Text>
                  To create a new snippet, start by selecting the content in
                  your document that you would like to capture.
                </Text>
              </StackItem>
              <StackItem>
                <AddSnippetForm
                  onClose={toggleEditSnippet}
                  category={category}
                />
              </StackItem>
            </Stack>
          </Box>
        )}
      </Panel>
    </span>
  );
};

export default memo(AddSnippet);
