/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from '@emotion/styled';
import { getTheme, Spinner, SpinnerSize, Text } from '@fluentui/react';
import Box from 'components/Box';
import React from 'react';
import { Draggable, DraggableProps } from 'react-beautiful-dnd';
import { Snippet } from 'store/snippets';
import EditSnippet from './snippet/EditSnippet';

const theme = getTheme();

interface SnippetListItemProps {
  snippet: Snippet;
  onDelete?: () => void;
}

const Container = styled(Box)`
  border-top: 1px solid ${theme.palette.neutralLighter};
  &:hover > div {
    background-color: ${theme.palette.neutralLighter};
  }
`;

const Title = styled(Box)`
  background-color: ${theme.palette.neutralLighterAlt};
  padding: 4px 0px 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Footer = styled(Box)`
  background-color: ${theme.palette.neutralLighterAlt};
  padding: 4px 8px 4px;
  display: flex;
`;

const Meta = styled(Box)`
  flex: 1;
  display: flex;
`;

const PaddedSpinner = styled(Spinner)`
  margin-left: 8px;
`;

const Actions = styled(Box)``;

const SnippetListItem: React.FC<SnippetListItemProps> = ({ snippet }) => {
  return (
    <Container>
      <Title>
        <Text variant="medium" title={snippet.name}>
          {snippet.name}
        </Text>
      </Title>
      <Footer>
        <Meta>
          {snippet.pending && (
            <>
              <Text variant="smallPlus">Processing</Text>
              <PaddedSpinner size={SpinnerSize.xSmall} />
            </>
          )}
        </Meta>
        <Actions>
          <EditSnippet snippet={snippet} />
        </Actions>
      </Footer>
    </Container>
  );
};

interface DraggableSnippetItemProps
  extends Omit<DraggableProps, 'draggableId' | 'children'> {
  snippet: Snippet;
}

export const DraggableSnippetItem: React.FC<DraggableSnippetItemProps> = ({
  snippet,
  ...props
}) => {
  return (
    <Draggable {...props} draggableId={snippet.id}>
      {(p) => (
        <div ref={p.innerRef} {...p.draggableProps} {...p.dragHandleProps}>
          <SnippetListItem snippet={snippet} />
        </div>
      )}
    </Draggable>
  );
};

export default SnippetListItem;
