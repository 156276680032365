import { wordRun } from 'components/hooks/useWordRun';
import { DocumentSet } from 'store/snippets';

interface CreateSnippetResponse {
  success: boolean;
  error?: 'no_content' | 'other';
  message?: string;
}
export async function createSnippet(
  documentSet: DocumentSet,
  name: string,
  catId?: string,
  inline?: boolean
): Promise<CreateSnippetResponse> {
  const result: CreateSnippetResponse = { success: true };
  let ooxmlValue: string | undefined = undefined;
  await wordRun(async (context) => {
    try {
      const range = context.document.getSelection();
      range.load({ isEmpty: true });

      await context.sync();
      if (!range.isEmpty) {
        const ooxml = range.getOoxml();
        await context.sync();
        ooxmlValue = ooxml.value;
      } else {
        result.success = false;
        result.error = 'no_content';
        result.message =
          'No content was selected, select a section of your document and try again';
      }
    } catch (e) {
      result.error = 'other';
      result.success = false;
      result.message = e.message;
    }
  });

  if (ooxmlValue) {
    await documentSet.addSnippet(ooxmlValue, name, catId, inline);
  }
  return result;
}
