import { Collection, Document, getFirebase } from '@garthomite/firestorter';
import {
  DocumentSource,
  IDocumentOptions,
} from '@garthomite/firestorter/lib/Types';
import { action, computed, makeObservable, observable } from 'mobx';
import { DocumentSet } from './snippets';

const COLL_WORKSPACE = 'workspace';

interface WorkspaceData {
  name: string;
  members: string[];
  editors: string[];
  owner: string;
}

export class Workspace extends Document<WorkspaceData> {
  uid?: string;

  private _documentSets?: Collection<DocumentSet>;

  @observable
  current?: DocumentSet;

  constructor(
    source?: DocumentSource,
    options?: IDocumentOptions,
    uid?: string
  ) {
    super(source, options);
    this.uid = uid;
    makeObservable(this);
  }

  get name() {
    return this.data.name;
  }

  addEditor(uid: string) {
    const fieldValue = getFirebase().firestore.FieldValue.arrayUnion([uid]);
    return this.update({
      members: fieldValue,
      editor: fieldValue,
    });
  }

  removeEditor(uid: string) {
    const fieldValue = getFirebase().firestore.FieldValue.arrayRemove(uid);
    return this.update({
      members: fieldValue,
      editor: fieldValue,
    });
  }

  addMember(uid: string) {
    const fieldValue = getFirebase().firestore.FieldValue.arrayUnion([uid]);
    return this.update({
      members: fieldValue,
    });
  }

  removeMember(uid: string) {
    const fieldValue = getFirebase().firestore.FieldValue.arrayRemove([uid]);
    this.update({
      members: fieldValue,
    });
  }

  get documentSets() {
    if (this._documentSets) return this._documentSets;

    this._documentSets = new Collection<DocumentSet>(
      () => `${this.path}/documentSet`,
      {
        createDocument: (source, options) => new DocumentSet(source, options),
      }
    );

    this._documentSets.query = (ref) =>
      ref
        .where('members', 'array-contains', this.uid || 'invalid')
        .orderBy('name');
    return this._documentSets;
  }

  @action
  async createDocSet(name: string) {
    if (!this.uid) {
      throw new Error('User must be logged in');
    }
    const newColl = await this.documentSets.add({
      name,
      owner: this.uid,
      members: [this.uid],
    });
    this.currentDocSet = newColl;
    return newColl;
  }

  @computed
  get currentDocSet(): DocumentSet | undefined {
    if (!this.uid) {
      return undefined;
    }

    if (this.current) {
      return this.current;
    }

    if (this._documentSets?.isLoaded) {
      const key = `${this.uid}_docset`;
      const currentId = localStorage.getItem(key);
      const prev = this._documentSets.docs.find((d) => d.id === currentId);
      if (prev) {
        return prev;
      }
      if (this._documentSets.docs.length > 0) {
        return this._documentSets.docs[0];
      }
    }

    return undefined;
  }

  set currentDocSet(docSet: DocumentSet | undefined) {
    const key = `${this.uid}_docset`;
    this.current = docSet;
    if (docSet?.id) {
      localStorage.setItem(key, docSet.id);
    } else {
      localStorage.removeItem(key);
    }
  }
}

export class WorkspaceStore {
  @observable
  _uid?: string;

  @observable
  collection?: Collection<Workspace>;

  @observable
  current?: Workspace;

  @observable
  _loading = false;

  constructor() {
    makeObservable(this);
  }

  set uid(uid: string | undefined) {
    this._uid = uid;
    if (this._uid) {
      this.collection = new Collection<Workspace>(COLL_WORKSPACE, {
        createDocument: (source, options) =>
          new Workspace(source, options, this._uid),
      });
      this.collection.query = (ref) =>
        ref.where('members', 'array-contains', this._uid);
    } else {
      this.collection = undefined;
    }
  }

  get uid() {
    return this._uid;
  }

  get workspaces() {
    return this.collection;
  }

  async createWorkspace(name: string) {
    if (!this._uid || !this.collection) {
      throw new Error('User must be logged in');
    }
    this.setLoading(true);
    const workspace = await this.collection.add({ name, members: [this._uid] });
    await workspace.createDocSet('My Snippets');
    this.setCurrentWorkspace(workspace);
    this.setLoading(false);
  }

  get _currentWorkspaceId(): string | undefined {
    const key = `${this._uid}_workspace`;
    return localStorage.getItem(key) || undefined;
  }

  set _currentWorkspaceId(id: string | undefined) {
    const key = `${this._uid}_workspace`;
    if (id) {
      localStorage.setItem(key, id);
    } else {
      localStorage.removeItem(key);
    }
  }

  @computed
  get currentWorkspace(): Workspace | undefined {
    if (!this.uid) {
      return undefined;
    }
    if (this.current) {
      return this.current;
    }
    if (this.workspaces?.isLoaded) {
      const key = `${this.uid}_docset`;
      const currentId = localStorage.getItem(key);
      const prev = this.workspaces.docs.find((d) => d.id === currentId);
      if (prev) {
        return prev;
      }
      if (this.workspaces.docs.length > 0) {
        return this.workspaces.docs[0];
      }
    }

    return undefined;
  }

  @computed
  get loading(): boolean {
    return !!(this._loading || this.collection?.isLoading);
  }

  @action
  setLoading(v: boolean) {
    this._loading = v;
  }

  @action
  setCurrentWorkspace(workspace: Workspace | undefined) {
    this._currentWorkspaceId = workspace?.id;
    this.current = workspace;
  }
}
