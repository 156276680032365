import { MouseEvent, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export function useNavigateTo(to: string, state?: unknown) {
  const history = useHistory();

  const handleClick = useCallback(
    (e?: MouseEvent<unknown>) => {
      history.push(to, state);
      if (e) {
        e.preventDefault();
      }
    },
    [to, history, state]
  );
  return handleClick;
}
