import React, { CSSProperties, useState } from 'react';
import { PrimaryButton, Stack, StackItem, Text } from '@fluentui/react';
import Box from 'components/Box';
// import { useNavigateTo } from 'components/hooks/useNavigateTo';
// import { routes } from 'routes';
import TextInput from 'components/TextInput';
import { useForm } from 'react-hook-form';
import { useAuthStore } from 'AppContextProvider';
import { FirebaseError } from 'firebase';
import AuthErrorMessage from 'account/AuthErrorMessage';

const fullWidth: CSSProperties = {
  width: '100%',
};

const REQUIRED = { required: true };

interface SignUpFormValues {
  name: string;
  email: string;
  password: string;
}

const SignUpForm: React.FC = () => {
  // const toCreateTeam = useNavigateTo(routes.create);
  const authStore = useAuthStore();
  const [signUpError, setSignUpError] = useState<FirebaseError | undefined>();
  const { register, handleSubmit, errors, setValue, formState } = useForm<
    SignUpFormValues
  >({
    reValidateMode: 'onChange',
  });

  const handler = handleSubmit(
    async ({ email, password, name }: SignUpFormValues) => {
      setSignUpError(undefined);
      try {
        await authStore.signUp(email, password, name);
      } catch (e) {
        setSignUpError(e as FirebaseError);
      }
    }
  );
  return (
    <Stack>
      <StackItem align="center">
        <Box py={20}>
          <Text variant="large">Let&apos;s create your account</Text>
        </Box>
      </StackItem>
      <StackItem>
        <form onSubmit={handler}>
          <Box>
            <TextInput
              name="name"
              label="Name"
              register={register}
              setValue={setValue}
              rules={REQUIRED}
              error={errors.name}
              required
            />
            <TextInput
              name="email"
              label="Email"
              register={register}
              setValue={setValue}
              rules={REQUIRED}
              error={errors.email}
              required
            />
            <TextInput
              name="password"
              type="password"
              label="Password"
              register={register}
              setValue={setValue}
              rules={REQUIRED}
              error={errors.password}
              required
            />
          </Box>
          {signUpError && (
            <Box my={8}>
              <AuthErrorMessage error={signUpError} />
            </Box>
          )}
          <Box my={20}>
            <PrimaryButton
              type="submit"
              style={fullWidth}
              disabled={formState.isSubmitting}
            >
              Create Account
            </PrimaryButton>
          </Box>
        </form>
      </StackItem>
    </Stack>
  );
};

export default SignUpForm;
