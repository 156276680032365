import { PivotItem } from '@fluentui/react';
import React, { useCallback } from 'react';

export function useSelectPanel(defaultKey: string) {
  const [panel, setSelectedPanel] = React.useState(defaultKey);

  const handlePivotClick = useCallback(
    (item?: PivotItem) => {
      setSelectedPanel(item?.props.itemKey || defaultKey);
    },
    [setSelectedPanel, defaultKey]
  );
  return { panel, handlePivotClick };
}
