import React from 'react';
import { useForm } from 'react-hook-form';
import {
  DefaultButton,
  PrimaryButton,
  Stack,
  StackItem,
} from '@fluentui/react';
import TextInput from 'components/TextInput';
import Box from 'components/Box';
import { useCurrentWorkspace } from 'store/useWorkspaces';

interface NewSingleEntryFormValues {
  value: string;
}

interface NewSingleEntryFormProps {
  onClose?: () => void;
  onSubmit: (value: string) => Promise<void>;
  label?: string;
  defaultValue?: string;
  primaryButtonLabel?: string;
}

const REQUIRED = { required: true };

const NewSingleEntryForm: React.FC<NewSingleEntryFormProps> = ({
  onClose,
  onSubmit,
  label,
  primaryButtonLabel,
  defaultValue = '',
}) => {
  const workspace = useCurrentWorkspace();

  const { register, handleSubmit, errors, setValue, formState } = useForm<
    NewSingleEntryFormValues
  >({
    reValidateMode: 'onChange',
    defaultValues: { value: defaultValue },
  });

  if (!workspace) {
    return null;
  }

  const handler = handleSubmit(({ value }: NewSingleEntryFormValues) =>
    onSubmit(value)
  );

  return (
    <form onSubmit={handler}>
      <Box mb={8}>
        <TextInput
          focusOnMount
          register={register}
          setValue={setValue}
          name="value"
          rules={REQUIRED}
          label={label}
          error={errors.value}
        />
      </Box>
      <Stack horizontal horizontalAlign="space-between">
        <StackItem>
          <PrimaryButton type="submit" disabled={formState.isSubmitting}>
            {primaryButtonLabel || 'Create'}
          </PrimaryButton>
        </StackItem>
        <StackItem>
          <DefaultButton onClick={onClose}>Cancel</DefaultButton>
        </StackItem>
      </Stack>
    </form>
  );
};

export default NewSingleEntryForm;
