import {
  DefaultButton,
  IStackTokens,
  PrimaryButton,
  Stack,
  StackItem,
} from '@fluentui/react';
import React from 'react';
import FormField, { DocumentField, DocumentFieldType } from './FormField';

const mockFields: DocumentField[] = [
  {
    label: 'Sample Field 1',
    name: 'sample field 1',
    type: DocumentFieldType.String,
  },
  {
    label: 'Sample Field 2',
    name: 'sample field 2',
    type: DocumentFieldType.String,
  },
  {
    label: 'Sample Field 3',
    name: 'sample field 3',
    type: DocumentFieldType.String,
  },
  {
    label: 'Sample Field 4',
    name: 'sample field 4',
    type: DocumentFieldType.String,
  },
  {
    label: 'Sample Field 5',
    name: 'sample field 5',
    type: DocumentFieldType.String,
  },
];

const stackTokens: IStackTokens = {
  childrenGap: 8,
};
const Populate: React.FC = () => {
  const fields = mockFields.map((f) => (
    <StackItem key={f.name}>
      <FormField field={f} />
      <StackItem />
    </StackItem>
  ));
  return (
    <Stack tokens={stackTokens}>
      {fields}
      <StackItem>
        <Stack
          horizontal
          wrap
          horizontalAlign="space-between"
          tokens={stackTokens}
        >
          <StackItem>
            <PrimaryButton>Populate</PrimaryButton>
          </StackItem>
          <StackItem>
            <DefaultButton>Clear</DefaultButton>
          </StackItem>
        </Stack>
      </StackItem>
    </Stack>
  );
};

export default Populate;
