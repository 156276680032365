import styled from '@emotion/styled';
import { FontIcon, Spinner, SpinnerSize } from '@fluentui/react';
import Box from 'components/Box';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { DocumentSet } from 'store/snippets';

interface DropFilesProps {
  documentSet?: DocumentSet;
  onUpload?: (fileId: string) => void;
}

const DropBox = styled(Box)`
  min-height: 200px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='4' stroke-dasharray='8' stroke-dashoffset='7' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
`;

const UploadIcon = styled(FontIcon)`
  width: 50px;
  font-size: 50px;
  height: 50px;
  cursor: pointer;
`;

const UploadMessage = styled(Box)`
  text-align: center;
  padding: 32px;
`;

const DropFiles: React.FC<DropFilesProps> = ({ documentSet, onUpload }) => {
  const [uploading, setLoading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setLoading(true);
      const promises = acceptedFiles.map(async (f) => {
        if (documentSet) {
          const fileId = await documentSet.addFileSnippet(f, f.name);
          if (onUpload) {
            onUpload(fileId);
          }
        }
      });
      await Promise.all(promises);
      setLoading(false);
    },
    [documentSet, onUpload, setLoading]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  if (!documentSet) {
    return null;
  }
  const message = isDragActive
    ? 'Drop the files here'
    : 'Drag and drop your word documents here, or click to select files';

  return (
    <Box>
      <div {...getRootProps()}>
        <DropBox>
          <input {...getInputProps()} />
          <UploadIcon iconName="BulkUpload" />
          <UploadMessage>{message}</UploadMessage>
        </DropBox>
      </div>
      {uploading && (
        <Box py={16}>
          <Spinner size={SpinnerSize.large} />
        </Box>
      )}
    </Box>
  );
};

export default DropFiles;
