import { Pivot, PivotItem, Stack, StackItem } from '@fluentui/react';
import Box from 'components/Box';
import MenuIcon from 'components/MenuIcon';
import Loading from 'Loading';
import { observer } from 'mobx-react';
import React from 'react';
import { useSelectPanel } from 'components/hooks/useSelectPanel';
import { useCurrentDocumentSets } from 'store/useDocumentSets';
import Upload from './Upload';
import ManageSnippets from './ManageSnippets';

const Manage: React.FC = () => {
  const documentSets = useCurrentDocumentSets();
  const { panel, handlePivotClick } = useSelectPanel('manage');

  if (!documentSets) {
    return null;
  }

  if (documentSets.isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Stack horizontal verticalAlign="center">
        <StackItem>
          <MenuIcon />
        </StackItem>
        <StackItem>
          <Pivot aria-label="Manage" headersOnly onLinkClick={handlePivotClick}>
            <PivotItem headerText="Manage" itemKey="manage" />
            <PivotItem headerText="Upload" itemKey="upload" />
          </Pivot>
        </StackItem>
      </Stack>
      <Box mr={8}>
        {panel === 'upload' && <Upload />}
        {panel === 'manage' && <ManageSnippets />}
      </Box>
    </>
  );
};

export default observer(Manage);
