import { Stack, StackItem, Text } from '@fluentui/react';
import { useWorkspaceStore } from 'AppContextProvider';
import Box from 'components/Box';
import { useNavigateTo } from 'components/hooks/useNavigateTo';
import MenuButton from 'components/MenuButtom';
import { observer } from 'mobx-react';
import React from 'react';
import { routes } from 'routes';
import { useWorkspaces } from 'store/useWorkspaces';
import { Workspace } from 'store/workspace';

const stackTokens = { childrenGap: 12 };

const PickWorkspace: React.FC = () => {
  const workspaceStore = useWorkspaceStore();
  const workspaces = useWorkspaces();
  const toBuild = useNavigateTo(routes.build);

  const pickWorkspace = (workspace?: Workspace) => {
    if (workspace) {
      workspaceStore.setCurrentWorkspace(workspace);
      toBuild(undefined);
    }
  };

  const spaces = workspaces.docs.map((w) => (
    <StackItem key={w.id} align="stretch">
      <MenuButton
        onClick={() => pickWorkspace(w)}
        name={w.name}
        active={workspaceStore.currentWorkspace === w.id}
      />
    </StackItem>
  ));

  return (
    <Stack>
      <StackItem align="center">
        <Box py={20}>
          <Text variant="xxLarge">Select Workspace</Text>
        </Box>
      </StackItem>

      <StackItem>
        <Stack tokens={stackTokens}>{spaces}</Stack>
      </StackItem>
    </Stack>
  );
};

export default observer(PickWorkspace);
