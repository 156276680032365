import styled from '@emotion/styled';
import {
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  GroupHeader,
  IColumn,
  Icon,
  IDetailsGroupDividerProps,
  IDetailsListProps,
  IRenderFunction,
  SelectionMode,
} from '@fluentui/react';
import { observer } from 'mobx-react';
import React from 'react';
import { Snippet } from 'store/snippets';
import { useCurrentDocSet } from 'store/useDocumentSets';
import { handleInsert } from './useHandleInsert';

interface GroupProps {
  title?: string;
}

const StyledIcon = styled(Icon)`
  font-size: 21px;
  width: 30px;
`;

const StyledHeaderText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 1px;
`;

const onRenderHeader: IRenderFunction<IDetailsGroupDividerProps> = (props) => {
  if (!props) {
    return null;
  }
  const { onToggleCollapse, group } = props;
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      onClick={() => onToggleCollapse && group && onToggleCollapse(group)}
      role="presentation"
    >
      <GroupHeader
        {...props}
        viewport={undefined}
        styles={{
          root: { cursor: 'pointer', minWidth: 1, maxHeight: 888 },
          groupHeaderContainer: { minWidth: 1 },
        }}
        onRenderTitle={(p) => (
          <StyledHeaderText>{p?.group?.name}</StyledHeaderText>
        )}
      />
    </div>
  );
};

const columns: IColumn[] = [
  {
    key: 'action',
    name: 'Add Snippet',
    isIconOnly: true,
    minWidth: 20,
    maxWidth: 30,
    onRender: (i) => {
      return (
        <StyledIcon
          iconName="PageAdd"
          onClick={() => handleInsert(i as Snippet)}
        />
      );
    },
  },
  {
    key: 'name',
    name: 'Name',
    fieldName: 'name',
    minWidth: 100,
    data: 'string',
    isPadded: false,
    onRender: (i) => {
      return <span title={i.name}>{i.name}</span>;
    },
  },
];

const onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
  if (props) {
    return <DetailsRow {...props} />;
  }
  return null;
};

const Groups: React.FC<GroupProps> = () => {
  const docSet = useCurrentDocSet();

  if (!docSet) {
    return null;
  }

  const { groups, snippets } = docSet.snippetGroups;

  return (
    <DetailsList
      styles={{
        root: { overflowX: 'none' },
        focusZone: { width: '100%' },
        contentWrapper: { cursor: 'pointer', userSelect: 'none' },
      }}
      indentWidth={8}
      groups={groups}
      items={snippets}
      groupProps={{ onRenderHeader }}
      columns={columns}
      compact
      cellStyleProps={{
        cellLeftPadding: 0,
        cellRightPadding: 0,
        cellExtraRightPadding: -100,
      }}
      selectionMode={SelectionMode.none}
      isHeaderVisible={false}
      layoutMode={DetailsListLayoutMode.justified}
      onRenderRow={onRenderRow}
    />
  );
};

export default observer(Groups);
