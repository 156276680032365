import styled from '@emotion/styled';
import { PrimaryButton, Stack, StackItem, Text } from '@fluentui/react';
import { useAuthStore } from 'AppContextProvider';
import Box from 'components/Box';
import React, { useEffect, useRef } from 'react';

const stackTokens = { childrenGap: 24 };

const CentreTextStack = styled(StackItem)`
  text-align: center;
`;

const Verify: React.FC = () => {
  const authStore = useAuthStore();
  const timerRef = useRef<number | undefined>();
  const counter = useRef<number>(0);
  useEffect(() => {
    if (!timerRef.current) {
      timerRef.current = window.setInterval(() => {
        authStore.reload();
        counter.current++;
        if (counter.current > 100) {
          window.clearInterval(timerRef.current);
        }
      }, 3000);
    }
    return () => window.clearInterval(timerRef.current);
  }, [authStore]);

  return (
    <Box pt={32}>
      <Stack tokens={stackTokens}>
        <StackItem align="center">
          <Text variant="xxLarge">Check your E-Mail</Text>
        </StackItem>
        <CentreTextStack align="center">
          <Text>
            In order to proceed we need first verify your e-mail address. We
            have sent instructions to {authStore.currentUser?.email}.
          </Text>
        </CentreTextStack>
        <StackItem align="center">
          <Text variant="large">Verified already?</Text>
        </StackItem>
        <StackItem align="center">
          <PrimaryButton onClick={() => authStore.reload()}>
            Try Again
          </PrimaryButton>
        </StackItem>
        <StackItem align="center">
          <Text variant="large">Can&apos;t find the e-mail?</Text>
        </StackItem>

        <StackItem align="center">
          <PrimaryButton onClick={() => authStore.resendEmail()}>
            Resend
          </PrimaryButton>
        </StackItem>
      </Stack>
    </Box>
  );
};

export default Verify;
