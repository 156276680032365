import Box from 'components/Box';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AuthType } from 'routes';
import { useAuthStore } from '../AppContextProvider';

const Auth: React.FC = () => {
  const authStore = useAuthStore();
  const { method } = useParams<{ method: AuthType }>();
  const { init, signInWithGoogle } = authStore;
  useEffect(() => {
    switch (method) {
      case AuthType.google:
        if (init) {
          signInWithGoogle();
        }
        break;
      default:
        break;
    }
  }, [method, init, signInWithGoogle]);
  return <Box>Hello Auth</Box>;
};

export default observer(Auth);
