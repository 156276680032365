import { useAuthStore } from 'AppContextProvider';
import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { routes } from 'routes';

const ProtectedRoute: React.FC<RouteProps> = ({ children, ...props }) => {
  const authStore = useAuthStore();
  const location = useLocation();

  return (
    <Route
      {...props}
      render={() => {
        if (authStore.init && authStore.currentUser) {
          return children;
        }
        authStore.redirectOnSignIn = location.pathname;
        return <Redirect to={routes.login} />;
      }}
    />
  );
};

export default ProtectedRoute;
