import {
  MessageBar,
  MessageBarType,
  Spinner,
  Stack,
  StackItem,
  Text,
} from '@fluentui/react';
import { useAuthStore, useWorkspaceStore } from 'AppContextProvider';
import Box from 'components/Box';
import GoogleButton from 'components/GoogleButton';
import MicrosoftButton from 'components/MicrosoftButton';
import { useAutorun } from 'components/hooks/useAutorun';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from 'routes';

import LoginForm from '../welcome/LoginForm';

const Login: React.FC = () => {
  const authStore = useAuthStore();
  const history = useHistory();
  const location = useLocation<{ fromReset: boolean }>();
  const workspaceStore = useWorkspaceStore();

  useAutorun(() => {
    if (authStore.currentUser) {
      if (workspaceStore.currentWorkspace) {
        history.push(routes.build);
      } else {
        history.push(routes.create);
      }
    }
  });

  return (
    <Stack tokens={{ childrenGap: 12 }}>
      <StackItem>
        {location.state?.fromReset && (
          <MessageBar messageBarType={MessageBarType.success}>
            Success! Check your email for instructions on resetting your account
          </MessageBar>
        )}
        <LoginForm />
      </StackItem>
      <StackItem align="center">
        <Box m={4}>
          <Text variant="large">Or</Text>
        </Box>
      </StackItem>
      <StackItem>
        <Stack tokens={{ childrenGap: 20 }} horizontalAlign="center">
          <StackItem>
            <GoogleButton />
          </StackItem>
          <StackItem>
            <MicrosoftButton />
          </StackItem>
        </Stack>
      </StackItem>
      <StackItem>{authStore.pending && <Spinner label="Working" />}</StackItem>
    </Stack>
  );
};

export default observer(Login);
