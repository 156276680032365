import React, { CSSProperties, useState } from 'react';
import {
  PrimaryButton,
  Spinner,
  Stack,
  StackItem,
  Text,
} from '@fluentui/react';
import Box from 'components/Box';
import RouterLink from 'components/RouterLink';
import { useAuthStore } from 'AppContextProvider';
import { useForm } from 'react-hook-form';
import TextInput from 'components/TextInput';
import { FirebaseError } from 'firebase';
import AuthErrorMessage from 'account/AuthErrorMessage';
import styled from '@emotion/styled';

const fullWidth: CSSProperties = {
  width: '100%',
};

const REQUIRED = { required: true };

interface LoginFormValues {
  email: string;
  password: string;
}

const FixedBox = styled.div`
  height: 24px;
  padding-top: 8px;
`;
const LoginForm: React.FC = () => {
  const authStore = useAuthStore();
  const [loginError, setLoginError] = useState<FirebaseError | undefined>();

  const { register, handleSubmit, errors, setValue, formState } = useForm<
    LoginFormValues
  >();

  const handler = handleSubmit(async ({ email, password }: LoginFormValues) => {
    try {
      setLoginError(undefined);
      await authStore.signIn(email, password);
    } catch (e) {
      setLoginError(e as FirebaseError);
    }
  });

  return (
    <form onSubmit={handler}>
      <Stack>
        <StackItem align="center">
          <Box py={20}>
            <Text variant="xxLarge">Sign In</Text>
          </Box>
        </StackItem>
        <StackItem>
          <Box>
            <TextInput
              name="email"
              label="Email"
              register={register}
              setValue={setValue}
              rules={REQUIRED}
              error={errors.email}
              required
            />
            <TextInput
              name="password"
              type="password"
              label="Password"
              register={register}
              setValue={setValue}
              rules={REQUIRED}
              error={errors.password}
              required
            />{' '}
            <RouterLink to="/recover">Forgot Password?</RouterLink>
          </Box>
          <FixedBox>
            {loginError && <AuthErrorMessage error={loginError} />}
            {formState.isSubmitting && (
              <Spinner label="Signing In" labelPosition="right" />
            )}
          </FixedBox>
          <Box mt={20}>
            <PrimaryButton
              type="submit"
              style={fullWidth}
              disabled={formState.isSubmitting}
            >
              Sign In
            </PrimaryButton>
          </Box>
        </StackItem>
      </Stack>
    </form>
  );
};

export default LoginForm;
