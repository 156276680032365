import { DefaultButton, Text } from '@fluentui/react';
import Box from 'components/Box';
import React, { useState } from 'react';
import { Snippet } from 'store/snippets';
import { useCurrentDocSet } from 'store/useDocumentSets';

interface DeleteSnippetButtonProps {
  snippet: Snippet;
  onDelete: () => void;
}

const DeleteSnippetButton: React.FC<DeleteSnippetButtonProps> = ({
  snippet,
  onDelete,
}) => {
  const [inProgress, setInProgress] = useState(false);
  const docSet = useCurrentDocSet();

  if (!docSet) {
    return null;
  }
  const handleDelete = async () => {
    setInProgress(true);
    await docSet.removeSnippet(snippet.id);
    setInProgress(false);
    onDelete();
  };

  return (
    <Box>
      <Box mt={48} mb={12} style={{ display: 'flex' }}>
        <DefaultButton
          style={{ width: '100%' }}
          onClick={handleDelete}
          disabled={inProgress}
        >
          Delete Snippet
        </DefaultButton>
      </Box>
      <Text>Warning: Deleting a snippet cannot be undone</Text>
    </Box>
  );
};

export default DeleteSnippetButton;
