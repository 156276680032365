import { Spinner, Stack, StackItem, Text } from '@fluentui/react';
import { useAuthStore } from 'AppContextProvider';
import Box from 'components/Box';
import GoogleButton from 'components/GoogleButton';
import MicrosoftButton from 'components/MicrosoftButton';
import RouterLink from 'components/RouterLink';
import React, { CSSProperties } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { useAutorun } from 'components/hooks/useAutorun';
import SignUpForm from './SignUpForm';

const fullWidth: CSSProperties = {
  width: '100%',
};

const SignUp: React.FC = () => {
  const authStore = useAuthStore();
  const history = useHistory();

  useAutorun(() => {
    if (authStore.currentUser) {
      history.push(routes.create);
    }
  });

  return (
    <Stack>
      <StackItem>
        <SignUpForm />
      </StackItem>
      <StackItem align="center">
        <Box mb={20}>
          <Text variant="large">Or</Text>
        </Box>
      </StackItem>
      <StackItem>
        <Stack tokens={{ childrenGap: 20 }}>
          <StackItem align="center">
            <GoogleButton style={fullWidth} signUp />
          </StackItem>
          <StackItem align="center">
            <MicrosoftButton style={fullWidth} signUp />
          </StackItem>
        </Stack>
      </StackItem>

      <StackItem align="center">
        <Box my={20}>
          <Text>
            Already have an account?{' '}
            <RouterLink to={routes.login}>Sign In</RouterLink>
          </Text>
        </Box>
        <Spinner label="Creating your account" />
      </StackItem>
    </Stack>
  );
};

export default SignUp;
