import { PrimaryButton, Stack, StackItem, Text } from '@fluentui/react';
import { useWorkspaceStore } from 'AppContextProvider';
import Box from 'components/Box';
import TextInput from 'components/TextInput';
import { useNavigateTo } from 'components/hooks/useNavigateTo';
import React from 'react';
import { useForm } from 'react-hook-form';
import { routes } from 'routes';

const stackTokens = { childrenGap: 12 };

interface CreateTeamFormValues {
  team: string;
}

const REQUIRED = { required: true };

const CreateTeamForm: React.FC = () => {
  const { register, handleSubmit, errors, setValue, formState } = useForm<
    CreateTeamFormValues
  >({
    reValidateMode: 'onChange',
  });
  const workspaceStore = useWorkspaceStore();
  const toBuild = useNavigateTo(routes.build);

  const onSubmit = handleSubmit(async ({ team }: CreateTeamFormValues) => {
    await workspaceStore.createWorkspace(team);
    toBuild();
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack tokens={stackTokens}>
        <StackItem align="center">
          <Box py={20}>
            <Text variant="xxLarge">Create Workspace</Text>
          </Box>
        </StackItem>
        <StackItem>
          <TextInput
            register={register}
            setValue={setValue}
            name="team"
            rules={REQUIRED}
            error={errors.team}
          />
        </StackItem>
        <StackItem>
          <PrimaryButton type="submit" disabled={formState.isSubmitting}>
            Create
          </PrimaryButton>
        </StackItem>
      </Stack>
    </form>
  );
};

export default CreateTeamForm;
