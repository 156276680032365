import Box from 'components/Box';
import React from 'react';

const AppContainer: React.FC = ({ children }) => {
  return (
    <Box width="100%">
      <Box pl={4} pr={4} ml="auto" mr="auto" maxWidth={420}>
        {children}
      </Box>
    </Box>
  );
};

export default AppContainer;
