import { IGroup } from '@fluentui/react';
import { Document, getFirestore, getFirebase } from '@garthomite/firestorter';
import {
  DocumentSource,
  IDocumentOptions,
} from '@garthomite/firestorter/lib/Types';
import 'firebase/storage';
import 'firebase/functions';

import { action, computed, makeObservable, observable } from 'mobx';
import {
  CreateSnippetRequest,
  DeleteSnippetRequest,
} from '../../functions/src/types';

export const DEFAULT_CATEGORY = 'default';

function compareSnippetOrder(a: SnippetData, b: SnippetData) {
  const aOrder = a.order || 0;
  const bOrder = b.order || 0;

  if (aOrder < bOrder) {
    return -1;
  }
  if (aOrder > bOrder) {
    return 1;
  }
  return 0;
}

const defaultCategory = [
  {
    id: DEFAULT_CATEGORY,
    name: 'Default',
  },
];

interface SnippetData {
  name: string;
  encoding: 'base64' | 'ooxml';
  pending?: boolean;
  ready?: boolean;
  path?: string;
  catId?: string;
  order?: number;
  deleting?: boolean;
  inline?: boolean;
}

export interface Snippet extends Omit<SnippetData, 'catId'> {
  id: string;
  catId: string;
}

export interface SnippetCategory {
  id: string;
  name: string;
}

export interface CategoryWithSnippets extends SnippetCategory {
  snippets: Snippet[];
}

interface DocumentSetData {
  name: string;
  owner: string;
  snippets?: Record<string, SnippetData>;
  categories: SnippetCategory[];
  members: string[];
}

export class DocumentSet extends Document<DocumentSetData> {
  @observable
  filter?: string;

  constructor(source?: DocumentSource, options?: IDocumentOptions) {
    super(source, options);
    makeObservable(this);
  }

  get name() {
    return this.data.name;
  }

  set name(name: string) {
    this.update({
      name,
    });
  }

  get workspaceId() {
    return `${this.path}`.split('/')[1];
  }

  // eslint-disable-next-line class-methods-use-this
  get snippets(): Snippet[] {
    const { snippets } = this.data;
    if (snippets) {
      return Object.keys(snippets)
        .map((id) => {
          const snippet = snippets[id];
          const catId = snippet.catId || DEFAULT_CATEGORY;
          return {
            ...snippet,
            id,
            catId,
          };
        })
        .sort(compareSnippetOrder)
        .filter((s) => !s.deleting);
    }
    return [];
  }

  get categories(): SnippetCategory[] {
    if (!this.data.categories || this.data.categories.length === 0) {
      return [...defaultCategory];
    }
    return this.data.categories;
  }

  set categories(categories: SnippetCategory[]) {
    this.update({
      categories,
    });
  }

  @action
  addCategory(name: string) {
    const cats = this.categories;
    this.categories.push({ name, id: generateId() });
    this.categories = cats;
  }

  getSnippetsFiltered(filters: string[]): Snippet[] {
    return this.snippets.filter((s) => filters.includes(s.id));
  }

  async removeSnippet(id: string) {
    if (!this.id) {
      throw new Error('Missing workspace or document set id');
    }
    await this.update({
      [`snippets.${id}.deleting`]: true,
    });
    const request: DeleteSnippetRequest = {
      docSetId: this.id,
      workspaceId: this.workspaceId,
      id,
    };
    const fn = getFirebase().functions().httpsCallable('deleteSnippet');
    // Don't need to wait for this.
    fn(request);
  }

  async addFileSnippet(blob: Blob, name: string): Promise<string> {
    const bucket = getFirebase().storage().ref();
    const id = generateId();
    const newUpload = bucket.child(
      `uploads/${this.workspaceId}/${this.id}/${id}`
    );
    await newUpload.put(blob);
    await this.update({
      [`snippets.${id}.name`]: name,
      [`snippets.${id}.pending`]: true,
    });
    return id;
  }

  async addSnippet(
    body: string,
    name: string,
    catId = DEFAULT_CATEGORY,
    inline?: boolean
  ): Promise<string> {
    const id = generateId();
    if (!this.id) {
      throw new Error('Missing workspace or document set id');
    }
    const fn = getFirebase().functions().httpsCallable('processCreateSnippet');
    await this.update({
      [`snippets.${id}.name`]: name,
      [`snippets.${id}.pending`]: true,
    });
    const request: CreateSnippetRequest = {
      id,
      workspaceId: this.workspaceId,
      docSetId: this.id,
      body,
      catId,
      title: name,
      inline,
    };
    await fn(request);
    return id;
  }

  async updateSnippet(id: string, name: string, inline?: boolean) {
    await this.update({
      [`snippets.${id}.name`]: name,
      [`snippets.${id}.inline`]: inline || false,
    });
  }

  @computed
  get snippetsByCategory() {
    return groupSnippets(this.categories, this.snippets);
  }

  @computed
  get snippetGroups(): { groups: IGroup[]; snippets: Snippet[] } {
    let startIndex = 0;
    const snippets = this.snippets.filter(
      (s) => s.name.toLocaleLowerCase().indexOf(this.filter || '') > -1
    );
    const groups = groupSnippets(this.categories, snippets)
      .filter((c) => c.snippets.length > 0)
      .map((c) => {
        const group = {
          count: c.snippets.length,
          startIndex,
          key: c.id,
          name: c.name,
          isCollapsed: !(c.id === DEFAULT_CATEGORY || this.filter),
        };
        startIndex += c.snippets.length;
        return group;
      });
    return { groups, snippets };
  }

  @action
  rearrange(
    current: CategoryWithSnippets[],
    snippetId: string,
    categoryId: string,
    index: number
  ) {
    const categories = [...current];

    // Find the changed snippet
    const snippet = this.snippets.find((s) => s.id === snippetId);
    if (!snippet) {
      return;
    }

    // Remove the snippet
    const oldCategory = categories.find((c) => c.id === snippet.catId);
    if (oldCategory) {
      oldCategory.snippets = oldCategory.snippets.filter(
        (s) => s.id !== snippet.id
      );
    }

    // Insert it in the new location
    const newCategory = categories.find((c) => c.id === categoryId);
    if (newCategory) {
      newCategory.snippets.splice(index, 0, snippet);
      newCategory.snippets = [...newCategory.snippets];
    }

    const toUpdate: Record<string, string | number> = {
      [`snippets.${snippetId}.catId`]: categoryId,
    };

    // Reset the order on every snippet in the category
    newCategory?.snippets.forEach((toOrder, idx) => {
      toUpdate[`snippets.${toOrder.id}.order`] = idx;
      // eslint-disable-next-line no-param-reassign
      toOrder.order = idx;
    });

    return { updated: categories, promise: this.update(toUpdate) };
  }

  @action
  changeCategoryOrder(prevIndex: number, newIndex: number) {
    const existing = this.categories;
    existing.splice(newIndex, 0, existing.splice(prevIndex, 1)[0]);
    this.categories = existing;
  }

  renameCategory(catId: string, newName: string) {
    const { categories } = this;
    const category = categories.find((c) => c.id === catId);
    if (category) {
      category.name = newName;
    }
    this.categories = categories;
  }

  removeCategory(category?: SnippetCategory) {
    if (category?.id !== DEFAULT_CATEGORY) {
      this.categories = this.categories.filter((c) => c.id !== category?.id);
    }
  }

  @computed
  get hasSnippets() {
    return this.snippets.filter((s) => s.ready).length > 0;
  }
}

function groupSnippets(categories: SnippetCategory[], snippets: Snippet[]) {
  const catMap = new Map<string, CategoryWithSnippets>(
    categories.map((cat) => [
      cat.id,
      {
        ...cat,
        snippets: [],
      },
    ])
  );

  snippets.forEach((s) => {
    const category = catMap.get(s.catId) || catMap.get(DEFAULT_CATEGORY);
    if (category) {
      category.snippets.push(s);
    }
  });
  return Array.from(catMap.values());
}

function generateId() {
  return getFirestore().collection('id').doc().id;
}
