import { Stack, StackItem, TextField } from '@fluentui/react';
import { observer } from 'mobx-react';
import React from 'react';
import CollectionSelector from 'components/CollectionSelector';
import { useCurrentDocSet } from 'store/useDocumentSets';
import Box from 'components/Box';
import NoSnippets from 'manage/NoSnippets';
import Groups from './Groups';

const stackTokens = { childrenGap: 8 };
const searchIconProps = { iconName: 'Search' };

const Assemble: React.FC = () => {
  const docSet = useCurrentDocSet();

  const handleSearchChange = (e: unknown, value?: string) => {
    if (docSet) {
      docSet.filter = value;
    }
  };

  if (!docSet) {
    return null;
  }

  const { hasSnippets } = docSet;
  return (
    <Stack tokens={stackTokens}>
      <StackItem>
        <Box mt={16}>
          <CollectionSelector documentSet={docSet} />
        </Box>
      </StackItem>
      {hasSnippets && (
        <StackItem>
          <TextField
            name="filter"
            iconProps={searchIconProps}
            placeholder="Search..."
            value={docSet?.filter}
            onChange={handleSearchChange}
          />
        </StackItem>
      )}
      <StackItem>
        {!hasSnippets && <NoSnippets />}
        {hasSnippets && <Groups />}
      </StackItem>
    </Stack>
  );
};

export default observer(Assemble);
