import React, { CSSProperties, useState } from 'react';
import { PrimaryButton, Stack, StackItem, Text } from '@fluentui/react';
import { useAuthStore } from 'AppContextProvider';
import Box from 'components/Box';
import { useForm } from 'react-hook-form';
import TextInput from 'components/TextInput';
import { FirebaseError } from 'firebase';
import AuthErrorMessage from 'account/AuthErrorMessage';
import { useNavigateTo } from 'components/hooks/useNavigateTo';
import { routes } from 'routes';

const fullWidth: CSSProperties = {
  width: '100%',
};

const REQUIRED = { required: true };

interface RecoverFormValues {
  email: string;
}

const Recover: React.FC = () => {
  const authStore = useAuthStore();
  const toLogin = useNavigateTo(routes.login, { fromReset: true });
  const [resetError, setResetError] = useState<FirebaseError | undefined>();

  const { register, handleSubmit, errors, setValue, formState } = useForm<
    RecoverFormValues
  >();
  const handler = handleSubmit(async ({ email }: RecoverFormValues) => {
    try {
      setResetError(undefined);
      await authStore.sendReset(email);
      toLogin();
    } catch (e) {
      setResetError(e as FirebaseError);
    }
  });
  return (
    <form onSubmit={handler}>
      <Stack>
        <StackItem align="center">
          <Box py={20}>
            <Text variant="xxLarge">Recover Account</Text>
          </Box>
        </StackItem>
        <StackItem>
          <Box>
            <TextInput
              name="email"
              label="Email"
              register={register}
              setValue={setValue}
              rules={REQUIRED}
              required
              error={errors.email}
            />
          </Box>
          {resetError && (
            <Box my={8}>
              <AuthErrorMessage error={resetError} />
            </Box>
          )}
          <Box mt={20}>
            <PrimaryButton
              type="submit"
              style={fullWidth}
              disabled={formState.isSubmitting}
            >
              Start
            </PrimaryButton>
          </Box>
        </StackItem>
      </Stack>
    </form>
  );
};

export default Recover;
