import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable/symbol';
import 'html5-history-api';

import React from 'react';
import { ThemeProvider } from '@fluentui/react-theme-provider';
import ReactDOM from 'react-dom';
import AppContextProvider from 'AppContextProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import { configure } from 'mobx';
import App from './App';
import { theme } from './theme';

configure({
  useProxies: 'never',
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);
