import React from 'react';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { FirebaseError } from 'firebase';

interface AuthErrorMessageProps {
  error?: FirebaseError;
}

function message(error: FirebaseError) {
  switch (error.code) {
    case 'auth/email-already-in-use':
      return <>This email is already in use, sign in instead?</>;
    case 'auth/invalid-email':
      return <>Invalid email address</>;
    case 'auth/user-not-found':
      return <>Account does not exist</>;
    case 'auth/wrong-password':
      return <>Invalid Email or Password</>;

    default:
      return error.message;
  }
}
const AuthErrorMessage: React.FC<AuthErrorMessageProps> = ({ error }) => {
  if (!error) {
    return null;
  }
  return (
    <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
      {message(error)}
    </MessageBar>
  );
};

export default AuthErrorMessage;
