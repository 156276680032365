/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useToggle } from 'components/hooks/useToggle';
import { Link } from '@fluentui/react';
import Box from 'components/Box';
import { DocumentSet } from 'store/snippets';
import RenameForm from './RenameForm';

interface RenameDocSetProps {
  documentSet?: DocumentSet;
}
const RenameDocSet: React.FC<RenameDocSetProps> = ({ documentSet }) => {
  const [showRename, toggle] = useToggle(false);
  if (!documentSet) {
    return null;
  }

  return (
    <Box mt={8}>
      {!showRename && (
        <Box mb={8}>
          <Link onClick={toggle}>Rename</Link>
        </Box>
      )}
      {showRename && <RenameForm documentSet={documentSet} onClose={toggle} />}
    </Box>
  );
};

export default RenameDocSet;
