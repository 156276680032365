/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from 'react';
import {
  Link,
  Panel,
  PanelType,
  Stack,
  StackItem,
  Text,
} from '@fluentui/react';
import Box from 'components/Box';
import { Snippet } from 'store/snippets';
import { useToggle } from 'components/hooks/useToggle';
import EditSnippetForm from './EditSnippetForm';

interface EditSnippetProps {
  snippet: Snippet;
}

const stackTokens = { childrenGap: 12 };

const EditSnippet: React.FC<EditSnippetProps> = ({ snippet }) => {
  const [open, toggleEditSnippet] = useToggle(false);
  return (
    <span>
      <Link variant="smallPlus" onClick={toggleEditSnippet}>
        Edit
      </Link>
      <Panel
        isOpen={open}
        onDismiss={toggleEditSnippet}
        type={PanelType.smallFixedFar}
        closeButtonAriaLabel="Close"
        headerText="Edit Snippet"
      >
        {open && (
          <Box mt={20}>
            <Stack tokens={stackTokens}>
              <StackItem>
                <Text>
                  To create a new snippet, start by selecting the content in
                  your document that you would like to capture.
                </Text>
              </StackItem>
              <StackItem>
                <EditSnippetForm
                  onClose={toggleEditSnippet}
                  snippet={snippet}
                />
              </StackItem>
            </Stack>
          </Box>
        )}
      </Panel>
    </span>
  );
};

export default memo(EditSnippet);
