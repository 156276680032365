import { Link, ILinkProps } from '@fluentui/react';
import React from 'react';

import { useNavigateTo } from './hooks/useNavigateTo';

interface RouterLinkProps extends ILinkProps {
  to: string;
}

const RouterLink: React.FC<RouterLinkProps> = ({ children, to, ...props }) => {
  const handleClick = useNavigateTo(to);

  return (
    <Link href={to} onClick={handleClick} {...props}>
      {children}
    </Link>
  );
};

export default RouterLink;
