import React from 'react';
import { DefaultButton, IButtonProps } from '@fluentui/react';
import { AuthType } from 'routes';
import { ReactComponent as GoogleIcon } from './google-icon.svg';
import Box from './Box';
import { useHandleAuth } from './useHandleAuth';

const styles = { flexContainer: { justifyContent: 'flex-start' } };

interface GoogleButtonProps extends IButtonProps {
  signUp?: boolean;
}

const GoogleButton: React.FC<GoogleButtonProps> = ({ style, signUp }) => {
  const handleAuth = useHandleAuth(AuthType.google);
  const newStyle = {
    ...style,
    padding: 20,
    fontFamily: 'Roboto',
    fontWeight: 500,
    maxWidth: 260,
  };
  return (
    <DefaultButton style={newStyle} styles={styles} onClick={handleAuth}>
      <Box mr={12} pt={2}>
        <GoogleIcon width="20" height="30" />
      </Box>
      {signUp ? 'Sign up with Google' : 'Sign in with Google'}
    </DefaultButton>
  );
};

export default GoogleButton;
