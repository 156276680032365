import { PartialTheme } from '@fluentui/react-theme-provider';
import { initializeIcons } from '@uifabric/icons';

initializeIcons();

export const theme: PartialTheme = {
  fonts: {
    xxLarge: {
      fontSize: '28px',
      fontWeight: 100,
    },
    xLarge: {
      fontSize: '21px',
      fontWeight: 100,
    },
  },
};
