import styled from '@emotion/styled';
import { DefaultButton, IButtonStyles } from '@fluentui/react';
import Box from 'components/Box';
import React from 'react';

interface MenuButtonProps {
  name: string;
  onClick?: () => void;
  active?: boolean;
}

const StyledBox = styled(Box)`
  display: flex;
`;

const buttonStyles: IButtonStyles = {
  label: {
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  textContainer: { minWidth: 1 },
  root: {
    flex: '1',
  },
};

const MenuButton: React.FC<MenuButtonProps> = ({ name, onClick, active }) => {
  return (
    <StyledBox>
      <DefaultButton onClick={onClick} styles={buttonStyles} checked={active}>
        {name}
      </DefaultButton>
    </StyledBox>
  );
};

export default MenuButton;
