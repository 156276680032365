import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { useCurrentDocSet } from 'store/useDocumentSets';
import TabContainer from './TabContainer';
import DropFiles from './DropFiles';
import SnippetListItem from './SnippetListItem';

const Upload: React.FC = () => {
  const currentDocSet = useCurrentDocSet();
  const uploadedRef = useRef(observable<string>([]));

  const handleOnUpload = action((fileId: string) => {
    uploadedRef.current.push(fileId);
  });

  const uploadedSnippets =
    currentDocSet?.getSnippetsFiltered(uploadedRef.current) || [];

  const uploaded = uploadedSnippets?.map((s) => (
    <SnippetListItem key={s.id} snippet={s} />
  ));

  return (
    <TabContainer>
      {currentDocSet && (
        <DropFiles documentSet={currentDocSet} onUpload={handleOnUpload} />
      )}
      {uploaded}
    </TabContainer>
  );
};

export default observer(Upload);
