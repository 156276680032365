import React from 'react';
import NewSingleEntryForm from 'components/NewSingleEntryForm';
import { useCurrentDocSet } from 'store/useDocumentSets';
import { observer } from 'mobx-react';
import { mergeStyles } from '@fluentui/react';
import Box from 'components/Box';
import { AnimationStyles } from '@fluentui/react/lib/Styling';
import { SnippetCategory } from 'store/snippets';

const boxClass = mergeStyles(AnimationStyles.slideDownIn20);

interface RenameCategoryFormProps {
  category: SnippetCategory;
  show?: boolean;
  onClose: () => void;
}

const RenameCategoryForm: React.FC<RenameCategoryFormProps> = ({
  category,
  onClose,
  show,
}) => {
  const docSet = useCurrentDocSet();

  if (!docSet) {
    return null;
  }

  if (!show) {
    return null;
  }
  const onSubmit = async (value: string) => {
    docSet.renameCategory(category.id, value);
    onClose();
  };

  return (
    <Box className={boxClass}>
      <NewSingleEntryForm
        onSubmit={onSubmit}
        onClose={onClose}
        defaultValue={category.name}
        primaryButtonLabel="Update"
      />
    </Box>
  );
};

export default observer(RenameCategoryForm);
