export enum AuthType {
  google = 'google',
  microsoft = 'microsoft',
}

export const routes = {
  home: '/',
  signup: '/signup',
  login: '/login',
  recover: '/recover',
  create: '/create',
  verify: '/verify',
  build: '/build',
  collection: '/collection',
  newCollection: '/collection/new',
  auth: (method?: AuthType) => `/auth/${method || ':method'}`,
} as const;
