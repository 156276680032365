import { memoizeFunction } from '@fluentui/react';
import React from 'react';

interface BoxProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  p?: number | string;
  py?: number | string;
  px?: number | string;
  pt?: number | string;
  pb?: number | string;
  pl?: number | string;
  pr?: number | string;
  m?: number | string;
  my?: number | string;
  mb?: number | string;
  mr?: number | string;
  ml?: number | string;
  mt?: number | string;
  width?: number | string;
  maxWidth?: number | string;
  display?: string;
  flex?: string;
  alignItems?: 'center';
}

const getStyle = memoizeFunction(
  ({
    p,
    py,
    px,
    pb,
    pt,
    pr,
    pl,
    m,
    my,
    ml,
    mr,
    mb,
    mt,
    width,
    maxWidth,
    style,
    display,
    flex,
    alignItems,
  }: BoxProps): React.CSSProperties => ({
    padding: p,
    paddingTop: pt || py,
    paddingBottom: pb || py,
    margin: m,
    marginBottom: mb || my,
    marginTop: mt || my,
    marginLeft: ml,
    marginRight: mr,
    paddingLeft: pl || px,
    paddingRight: pr || px,
    width,
    maxWidth,
    display,
    flex,
    alignItems,
    ...style,
  })
);

const Box: React.FC<BoxProps> = ({ children, className, ...props }) => {
  return (
    <div style={getStyle(props)} className={className}>
      {children}
    </div>
  );
};

export default Box;
