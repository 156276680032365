import { Spinner, SpinnerSize, Stack, StackItem, Text } from '@fluentui/react';
import Box from 'components/Box';
import React from 'react';

const Loading: React.FC = () => {
  return (
    <Box width="100%">
      <Box mt={100} ml="auto" mr="auto" maxWidth={420}>
        <Stack horizontalAlign="center" tokens={{ childrenGap: 20 }}>
          <StackItem>
            <Text variant="xxLarge">Loading</Text>
          </StackItem>

          <StackItem>
            <Spinner size={SpinnerSize.large} />
          </StackItem>
        </Stack>
      </Box>
    </Box>
  );
};

export default Loading;
