import * as firebase from 'firebase/app';
import 'firebase/auth';
import { action, makeObservable, observable } from 'mobx';

export class AuthStore {
  private auth;

  @observable
  init = false;

  @observable
  name?: string;

  @observable
  pending = false;

  @observable
  verified = false;

  @observable
  currentUser?: firebase.User | null;

  redirectOnSignIn?: string;

  onSignIn?: (user: firebase.User) => void;

  onSignOut?: () => void;

  constructor() {
    this.handleAuthChange = this.handleAuthChange.bind(this);
    this.signInWithGoogle = this.signInWithGoogle.bind(this);

    makeObservable(this);

    this.auth = firebase.auth();
    this.currentUser = this.auth.currentUser;
    this.pending = true;

    this.auth.getRedirectResult().then((user) => {
      if (window.Office && user.user) {
        try {
          Office.context.ui.messageParent('close');
        } catch (e) {
          // DO Nothing
        }
      }
    });
    this.auth.onAuthStateChanged(this.handleAuthChange);
    this.auth.onIdTokenChanged(() => {
      setTimeout(() => {
        action(() => {
          this.init = true;
        })();
      }, 100);
    });
  }

  async signInWithMS(): Promise<void> {
    if (this.currentUser) {
      return;
    }
    this.pending = true;
    await this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    await this.auth.signInWithRedirect(provider);
  }

  @action
  async signInWithGoogle(): Promise<void> {
    if (this.currentUser) {
      return;
    }
    this.pending = true;
    await this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    await this.auth.signInWithRedirect(provider);
  }

  async signUp(
    email: string,
    password: string,
    displayName?: string
  ): Promise<void> {
    await this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    const cred = await this.auth.createUserWithEmailAndPassword(
      email,
      password
    );
    if (displayName) {
      await cred.user?.updateProfile({ displayName });
    }
    await cred.user?.sendEmailVerification();
  }

  async signOut(): Promise<void> {
    await this.auth.signOut();
  }

  async signIn(email: string, password: string): Promise<void> {
    await this.auth.signInWithEmailAndPassword(email, password);
  }

  async resendEmail(): Promise<void> {
    if (!this.currentUser) {
      throw new Error('Not logged in');
    }
    return this.currentUser?.sendEmailVerification();
  }

  @action
  async reload(): Promise<void> {
    if (!this.currentUser) {
      throw new Error('Not logged in');
    }
    await this.currentUser.reload();
    const verified = this.currentUser.emailVerified;
    action(() => {
      this.verified = verified;
    })();
  }

  sendReset(email: string): Promise<void> {
    return this.auth.sendPasswordResetEmail(email);
  }

  @action
  handleAuthChange(user: firebase.User | null) {
    this.pending = false;
    const wasSignedIn = !!this.currentUser;
    this.currentUser = user;
    // User signed in, there is a display name
    if (user) {
      this.verified = user.emailVerified;
      this.name = user.displayName || user.email || '';
      if (this.onSignIn) {
        this.onSignIn(user);
      }
    }
    // User signed out.
    if (!user) {
      this.verified = false;
      this.name = undefined;
      if (wasSignedIn && this.onSignOut) {
        this.onSignOut();
      }
    } else {
      this.init = true;
    }
  }
}
