/// <reference types="@types/office-js" />
import React, { useContext, useRef } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { initFirestorter } from '@garthomite/firestorter';
import { AuthStore } from 'store/auth';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { WorkspaceStore } from 'store/workspace';

const context = React.createContext<AppContext | null>(null);

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_BUCKET,
});

initFirestorter({ firebase });

class AppContext {
  workspaceStore = new WorkspaceStore();

  authStore: AuthStore;

  constructor() {
    if (window.Office) {
      Office.initialize = () => {
        // eslint-disable-next-line no-console
        console.log('office loaded');
      };
    }
    this.authStore = new AuthStore();
  }
}

const AppContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const contextValue = useRef(new AppContext());
  const { authStore } = contextValue.current;

  authStore.onSignIn = (user) => {
    contextValue.current.workspaceStore.uid = user.uid;
  };
  authStore.onSignOut = () => history.push(routes.home);
  return (
    <context.Provider value={contextValue.current}>{children}</context.Provider>
  );
};

export function useApplicationContext(): AppContext {
  const appContext = useContext(context);
  if (!appContext) {
    throw new Error(
      'Unable to find application context, did you specify the AppContextProvider?'
    );
  }
  return appContext;
}

export function useAuthStore(): AuthStore {
  return useApplicationContext().authStore;
}

export function useWorkspaceStore(): WorkspaceStore {
  return useApplicationContext().workspaceStore;
}

export default AppContextProvider;
