import React, { useEffect, useRef } from 'react';
import { RHFInput } from 'react-hook-form-input';
import { Props } from 'react-hook-form-input/dist/types';
import { ITextField, TextField } from '@fluentui/react';
import { FieldError } from 'react-hook-form';

interface TextInputProps {
  name: string;
  register: Props['register'];
  error?: FieldError;
  setValue: (name: string, value: string) => void;
  rules: Props['rules'];
  label?: string;
  defaultValue?: string;
  focusOnMount?: boolean;
  type?: HTMLInputElement['type'];
  required?: boolean;
}

function errorMessage(e?: FieldError) {
  if (e?.type === 'required') {
    return 'Required';
  }
}

const TextInput: React.FC<TextInputProps> = ({
  register,
  name,
  error,
  setValue,
  label,
  rules,
  defaultValue,
  focusOnMount,
  type,
  required,
}) => {
  const ref = useRef<ITextField | null>();

  useEffect(() => {
    if (focusOnMount) {
      ref.current?.focus();
    }
  }, [focusOnMount]);

  return (
    <RHFInput
      defaultValue={defaultValue}
      name={name}
      as={
        <TextField
          label={label}
          errorMessage={errorMessage(error)}
          componentRef={(input) => {
            ref.current = input;
          }}
          type={type}
          required={required}
        />
      }
      register={register}
      rules={rules}
      setValue={(a: string, v: string) => {
        if (setValue) {
          setValue(a, v);
        }
      }}
    />
  );
};

export default TextInput;
