import React, { useEffect, useState } from 'react';
import TextInput from 'components/TextInput';
import {
  Checkbox,
  DefaultButton,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  StackItem,
} from '@fluentui/react';
import { useForm } from 'react-hook-form';
import { useCurrentDocSet } from 'store/useDocumentSets';
import { SnippetCategory } from 'store/snippets';
import { createSnippet } from './createSnippet';

interface AddSnippetFormValues {
  name: string;
  inline: boolean;
}

const REQUIRED = { required: true };

interface AddSnippetFormProps {
  onClose?: () => void;
  category?: SnippetCategory;
}

const stackTokens = { childrenGap: 12 };

const AddSnippetForm: React.FC<AddSnippetFormProps> = ({
  onClose,
  category,
}) => {
  const docSet = useCurrentDocSet();
  const [formError, setFormError] = useState<undefined | string>();

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    formState,
    reset,
  } = useForm<AddSnippetFormValues>({
    reValidateMode: 'onChange',
    defaultValues: { name: '', inline: true },
  });

  useEffect(() => {
    register({ name: 'inline' });
  }, [register]);

  if (!docSet) {
    return null;
  }

  const handler = handleSubmit(
    async ({ name, inline }: AddSnippetFormValues) => {
      const result = await createSnippet(docSet, name, category?.id, inline);
      setFormError(result.message);
      if (result.success) {
        if (onClose) {
          onClose();
        }
        reset();
      }
    }
  );

  return (
    <form onSubmit={handler}>
      <Stack tokens={stackTokens}>
        <StackItem>
          <TextInput
            focusOnMount
            register={register}
            setValue={setValue}
            name="name"
            rules={REQUIRED}
            label="Name"
            error={errors.name}
          />
        </StackItem>
        <StackItem>
          <Checkbox
            label="Inline snippet"
            name="inline"
            onChange={(_, checked) => setValue('inline', checked || false)}
          />
        </StackItem>
        {formError && (
          <StackItem>
            <MessageBar messageBarType={MessageBarType.error} isMultiline>
              {formError}
            </MessageBar>
          </StackItem>
        )}
        <StackItem>
          <Stack horizontal horizontalAlign="space-between">
            <StackItem>
              <PrimaryButton type="submit" disabled={formState.isSubmitting}>
                Create
              </PrimaryButton>
            </StackItem>
            <StackItem>
              <DefaultButton onClick={onClose}>Cancel</DefaultButton>
            </StackItem>
          </Stack>
        </StackItem>
        {formState.isSubmitting && (
          <StackItem>
            <ProgressIndicator label="Creating Snippet" />
          </StackItem>
        )}
      </Stack>
    </form>
  );
};

export default AddSnippetForm;
