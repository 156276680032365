import React from 'react';
import { useCurrentWorkspace } from 'store/useWorkspaces';
import NewSingleEntryForm from 'components/NewSingleEntryForm';

interface NewDocSetFormProps {
  onClose?: () => void;
  onComplete?: () => void;
}

const NewDocSetForm: React.FC<NewDocSetFormProps> = ({
  onClose,
  onComplete,
}) => {
  const { workspace } = useCurrentWorkspace();

  if (!workspace) {
    return null;
  }

  const onSubmit = async (value: string) => {
    await workspace.createDocSet(value);
    if (onComplete) {
      onComplete();
    }
  };

  return (
    <NewSingleEntryForm
      onSubmit={onSubmit}
      label="Collection Name"
      onClose={onClose}
    />

    // <form onSubmit={onSubmit}>
    //   <Box mb={8}>
    //     <TextInput
    //       register={register}
    //       setValue={setValue}
    //       name="name"
    //       rules={REQUIRED}
    //       label="Collection Name"
    //       error={errors.name}
    //     />
    //   </Box>
    //   <Stack horizontal horizontalAlign="space-between">
    //     <StackItem>
    //       <PrimaryButton type="submit" disabled={formState.isSubmitting}>
    //         Create
    //       </PrimaryButton>
    //     </StackItem>
    //     <StackItem>
    //       <DefaultButton type="submit" onClick={onClose}>
    //         Cancel
    //       </DefaultButton>
    //     </StackItem>
    //   </Stack>
    // </form>
  );
};

export default NewDocSetForm;
